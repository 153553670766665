import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const PublicRoute = ({ children }) => {
  const isAuthenticated = !!Cookies.get('accessToken');

  return !isAuthenticated ? children : <Navigate to="/challenge" />;
};

export default PublicRoute;
