import React from 'react'
import { Link } from 'react-router-dom'

function NotFoundPage() {
  return (
    <div className="w-full h-full flex-col flex items-center gap-2 justify-center overflow-hidden ">
      <h1 className='ltr text-[36px]  text-center font-[700]  leading-[45px]'>90 days <span className='uppercase text-orange-500'>#w3r</span> daily routine </h1>
      <p className='ltr text-center  text-[16px] font-[400] challenge-form'>خدم و تقاتل باش تكون 1%  من الناس و عفط ليهوم على الراس 💪📖🌟</p>
      <div className=" flex items-center justify-center  ">
        <p className='md:text-[20rem]  text-[10rem] font-[800]'>404</p>
      </div>
      <Link to='/challenge' className='flex justify-center items-center px-5 py-3 text-[16px] text-white font-[700] bg-orange-500 rounded-full'>
        Return to challenge
      </Link>
    </div>

  )
}

export default NotFoundPage