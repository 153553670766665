import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axios/axiosConfig';
import { toast } from 'react-toastify';

function Authentification() {
    const navigate = useNavigate()
    const BackUrl = process.env.REACT_APP_BACKEND_URL_SUNCTAM


    const [message, setMessage] = useState('')
    const [Switch, setSwitch] = useState(false)
    const [InputsErrors, setInputsErrors] = useState([])

    const [registerData, setRegisterData] = useState({ fullName: null, email: null, phone: null, password: null, passwordConfirm: null })
    const [loginData, setLoginData] = useState({ email: null, password: null })

    const handleOnChangeRegister = (e) => {
        setRegisterData({ ...registerData, [e.target.name]: e.target.value })
    }
    const handleOnChangeLogin = (e) => {
        setLoginData({ ...loginData, [e.target.name]: e.target.value })
    }

    // useEffect(() => {
    //     axios.get(`${BackUrl}/sanctum/csrf-cookie`, {
    //         headers: {
    //             Accept: "application/json",
    //             "Content-Type": "application/json",


    //         },
    //         withCredentials: true,
    //     })
    // }, [])


    const register = async (e) => {
        e.preventDefault();
        if (!registerData.email || !registerData.password || !registerData.passwordConfirm || !registerData.fullName || !registerData.phone) {
            setMessage('All feilds are required !')
        }
        else if (registerData.password !== registerData.passwordConfirm) {
            setMessage('confirm password not correct !')

        }
        else {
            const result = await axiosInstance.post('/users/register', registerData)
            if (result.data.success) {
                setMessage('')
                toast.success(result.data.message);
                setInputsErrors('')
                setSwitch(!Switch)
                setRegisterData({ fullName: null, email: null, phone: null, password: null, passwordConfirm: null })
            }
            else {
                setInputsErrors(result.data.errors)
                toast.error(result.data.errors[0]);
            }
        }

    }

    const login = async (e) => {
        e.preventDefault();

        if (!loginData.email || !loginData.password) {
            setMessage('Tous les champs doivent être remplis')

        }
        else {
            const result = await axiosInstance.post('/users/login', loginData)
            if (result.data.authenticated) {
                if (result.data.active) {
                    Cookies.set('accessToken', result.data.Token, { expires: 30 });
                    Cookies.set('role', result.data.userData.role , { expires: 30 });

                    setMessage('')
                    setLoginData({ email: null, phone: null })
                    if (result.data.userData.role === 'Admin') navigate('/users')
                    else navigate('/challenge')
                }
                else {
                    setMessage(result.data.message)
                }
            }
            else {
                setMessage(result.data.message)
            }
        }

    }
    return (
        <div><div className="fixed ltr left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-50 py-10">
            <div className="max-h-full mx-3 flex justify-center items-center p-6 w-fit max-w-xl overflow-y-auto rounded-2xl bg-white">
                <div className="w-full">
                    {Switch ?
                        (
                            <div className=" max-w-[400px] mx-auto">
                                <div className="mb-8 text-center">
                                    <h1 className="mb-4 text-3xl font-extrabold">90 days <span className='uppercase text-orange-500'>#w3r</span> daily routine</h1>
                                    <p className='ltr text-[16px] font-[400]'>Commit, push, read, reflect. Transform yourself in 90 days. Let's do this! 💪📖🌟</p>
                                </div>
                                {message &&
                                    <div className='w-full border border-red-200 bg-red-50 h-12 rounded-full flex text-red-500 justify-start items-center gap-3 px-4 py-2 mb-6'>
                                        <p className='ltr text-[16px] font-[600]'>{message} !</p>

                                    </div>

                                }

                                <div className="max-w-sm space-y-3 mb-6">
                                    <input onChange={(e) => handleOnChangeRegister(e)} type="text" name="fullName" className="py-3 border border-gray-200 px-4 block w-full h-14 border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " placeholder="Enter your fullName" />
                                    <p class="mt-2 text-sm text-red-600 dark:text-red-500"><span class="font-medium"></span>{InputsErrors.hasOwnProperty('fullName') && InputsErrors.fullName}</p>

                                </div>
                                <div className="max-w-sm space-y-3 mb-6">
                                    <input onChange={(e) => handleOnChangeRegister(e)} type="number" name="phone" className="py-3 border border-gray-200 px-4 block w-full h-14 border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " placeholder="Enter your phone number" />
                                    <p class="mt-2 text-sm text-red-600 dark:text-red-500"><span class="font-medium"></span>{InputsErrors.hasOwnProperty('Region') && InputsErrors.Region}</p>

                                </div>
                                <div className="max-w-sm space-y-3 mb-6">
                                    <input onChange={(e) => handleOnChangeRegister(e)} type="email" name="email" className="py-3 border border-gray-200 px-4 block w-full h-14 border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " placeholder="Enter your email " />
                                    <p class="mt-2 text-sm text-red-600 dark:text-red-500"><span class="font-medium"></span>{InputsErrors.hasOwnProperty('email') && InputsErrors.email}</p>

                                </div>
                                <div className="max-w-sm space-y-3 mb-6">
                                    <input onChange={(e) => handleOnChangeRegister(e)} type="password" name="password" className="py-3 border border-gray-200 px-4 block w-full h-14 border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " placeholder="Enter your password" />
                                    <p class="mt-2 text-sm text-red-600 dark:text-red-500"><span class="font-medium"></span>{InputsErrors.hasOwnProperty('Region') && InputsErrors.Region}</p>

                                </div>
                                <div className="max-w-sm space-y-3 mb-6">
                                    <input onChange={(e) => handleOnChangeRegister(e)} type="password" name="passwordConfirm" className="py-3 border border-gray-200 px-4 block w-full h-14 border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " placeholder="Confirm your password" />
                                </div>

                                <div className="space-y-4">
                                    <button onClick={(e) => register(e)} className="p-3 bg-black rounded-full text-white w-full font-semibold">Register</button>
                                </div>
                                <div className='w-full flex items-center justify-center pt-3'>
                                    <a className='text-center underline cursor-pointer' onClick={() => setSwitch(!Switch)}> {Switch ? 'register !' : 'sign in !'} </a>
                                </div>
                            </div>
                        )
                        :

                        (
                            <div className=" max-w-[400px] mx-auto">
                                <div className="mb-8 text-center">
                                    <h1 className="mb-4 text-3xl font-extrabold">90 days <span className='uppercase text-orange-500'>#w3r</span> daily routine</h1>
                                    <p className='ltr text-[16px] font-[400]'>Commit, push, read, reflect. Transform yourself in 90 days. Let's do this! 💪📖🌟</p>
                                </div>
                                {message &&
                                    <div className='w-full border border-red-200 bg-red-50 h-12 rounded-full flex text-red-500 justify-start items-center gap-3 px-4 py-2 mb-6'>
                                        <p className='ltr text-[16px] font-[600]'>{message} !</p>

                                    </div>

                                }


                                <div className="max-w-sm space-y-3 mb-6">
                                    <input onChange={(e) => handleOnChangeLogin(e)} type="email" name="email" className="py-3 border border-gray-200 px-4 block w-full h-14 border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " placeholder="Enter your email " />
                                </div>
                                <div className="max-w-sm space-y-3 mb-6">
                                    <input onChange={(e) => handleOnChangeLogin(e)} type="password" name="password" className="py-3 border border-gray-200 px-4 block w-full h-14 border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " placeholder="Enter your password" />
                                </div>


                                <div className="space-y-4">
                                    <button onClick={(e) => login(e)} className="p-3 bg-black rounded-full text-white w-full font-semibold">Sign in</button>
                                </div>
                                <div className='w-full flex items-center justify-center pt-3'>
                                    <a className='text-center underline cursor-pointer' onClick={() => setSwitch(!Switch)}> {!Switch ? 'register !' : 'sign in !'} </a>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div></div>
    )
}

export default Authentification