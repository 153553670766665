import React, { useState, useEffect, useRef } from 'react'
import reading from '../../assets/images/reading.png'
import writegoals from '../../assets/images/writegoals.png'
import walking from '../../assets/images/walking.png'
import deepwork from '../../assets/images/deepwork.png'
import learning from '../../assets/images/learning.png'
import diet from '../../assets/images/diet.png'
import workout from '../../assets/images/workout.png'
import html2canvas from 'html2canvas'
import ModalPrint from './ModalPrint'
import { toast } from 'react-toastify'
import axiosInstance from '../../utils/axios/axiosConfig'
import Cookies from 'js-cookie'
import { Dropdown } from '../../components'

const ChallengeForm = () => {

  const [challengeDayData, setChallengeDayData] = useState({})
  const [user, setUser] = useState({})
  const [loadingSave, setLoadingSave] = useState(false)




  const formRef = useRef(null);


  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    const updatedValue = type === 'checkbox' ? checked : value;
    const updatedchallengeDayData = {
      ...challengeDayData,
      [name]: updatedValue,
    };
    setChallengeDayData(updatedchallengeDayData);
  };
  let token = Cookies.get('accessToken');


  useEffect(() => {
    getChallenge()
    if(token) getUser()
  }, [])


  const getChallenge = async () => {
    const result = await axiosInstance.post('/challenge/get', { challenge_date: null })
    if (result.data.success) {
      setChallengeDayData(result.data.data)
    } else {
      toast.error(result.data.message)
    }
  }
  const getUser = async () => {
    const result = await axiosInstance.post('/users/getOne')
    if (result.data.success) {
      setUser(result.data.data)
    } else {
      toast.error(result.data.message)
    }
  }
  const UpdateChallenge = async (e) => {
    setLoadingSave(true)
    const result = await axiosInstance.post('/challenge/update', challengeDayData)
    if (result.data.success) {
      setChallengeDayData(result.data.data)
      toast.success(result.data.message)
      setLoadingSave(false)

    } else {
      toast.error(result.data.message)
    }
    setLoadingSave(false)

  }
  const getChallengePrevOrNext = async (e, type) => {
    e.preventDefault();

    let date = challengeDayData.challenge_date;

    if (type === 'prev') {
      date = new Date(date);
      date.setDate(date.getDate() - 1);
    } else if (type === 'next') {
      date = new Date(date);
      date.setDate(date.getDate() + 1);
    }

    const result = await axiosInstance.post('/challenge/get', { challenge_date: date.toISOString() });

    if (result.data.success) {
      setChallengeDayData(result.data.data);
    } else {
      toast.error(result.data.message);
    }
  };


  const [loading, setLoading] = useState(false)


  const downloadImage = () => {
    if (challengeDayData.reading && challengeDayData.writegoals && challengeDayData.walking && challengeDayData.deepwork && challengeDayData.learning && challengeDayData.diet && challengeDayData.workout) {
      setLoading(true)
      const div = formRef.current;

      html2canvas(div).then(canvas => {
        const dataUrl = canvas.toDataURL('image/png');

        // Create a temporary link element
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'challengeImage.png';

        // Trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        setLoading(false)
      });
    } else {
      toast.warning("You haven't completed you goals for today yet !!")
    }

  };


  const handleEmailClick = () => {
    const recipientEmail = 'contact@w3r.ma';
    const mailtoLink = `mailto:${recipientEmail}`;
    window.location.href = mailtoLink;
  };




  return (
    <div>
      <div className='relative w-full md:w-[30%] h-full mx-auto flex flex-col justify-center bg-orange-50 items-center gap-5 py-6'>
                        <Dropdown />

        <header className="w-full grid gap-4 text-center ">
          <h1 className='ltr text-[36px] font-[700] md:px-12 leading-[45px]'>90 days <span className='uppercase text-orange-500'>#w3r</span> daily routine</h1>
          <p className=' text-[16px] font-[400] challenge-form'>خدم و تقاتل باش تكون 1%  من الناس و عفط ليهوم على الراس 💪📖🌟</p>
        </header>

        <main className=' w-full h-full'>
          <form className='challenge-form'>
            <div className='grid place-items-center gap-3 w-full '>
              <div className='flex justify-center items-center gap-2 w-full ltr'>
                <button disabled={challengeDayData.day_number === 1 ? true : false} onClick={(e) => getChallengePrevOrNext(e, 'prev')} className='flex gap-1 text-[15px] font-semibold text-gray-700 cursor-pointer select-none border-gray-200 border bg-white rounded-full   justify-center py-1 w-full items-center '>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-undo"><path d="M3 7v6h6" /><path d="M21 17a9 9 0 0 0-9-9 9 9 0 0 0-6 2.3L3 13" /></svg>
                  Previous Day
                </button>
                <div className='flex   flex-col justify-center py-1 w-full items-center '>
                  <h1 className='text-[25px] font-semibold text-gray-900 cursor-pointer select-none'>Day <span className='text-orange-500'>{challengeDayData.day_number}</span> </h1>
                </div>
                <button disabled={challengeDayData.day_number === 90 ? true : false} onClick={(e) => getChallengePrevOrNext(e, 'next')} className='flex text-[15px] gap-1 font-semibold text-gray-700 cursor-pointer border-gray-200 border bg-white rounded-full   justify-center py-1 w-full items-center '>
                  Next Day
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-redo"><path d="M21 7v6h-6" /><path d="M3 17a9 9 0 0 1 9-9 9 9 0 0 1 6 2.3l3 2.7" /></svg>                </button>

              </div>

              <div className='flex justify-center items-center gap-2 w-full mb-3 ltr'>
                <div className='flex border-gray-200 border bg-white rounded-full  flex-col justify-center py-1 w-full items-center '>
                  <h1 className='text-[15px] font-semibold text-gray-700 cursor-pointer select-none'>Challenge Start</h1>
                  <p className='text-[15px] font-[500] text-gray-700 cursor-pointer select-none -mt-1'>{user.startChallenge}</p>
                </div>
                <div className='flex border-gray-200 border bg-white rounded-full  flex-col justify-center py-1 w-full items-center '>
                  <h1 className='text-[15px] font-semibold text-gray-700 cursor-pointer select-none'>Current Date</h1>
                  <p className='text-[15px] font-[500] text-gray-700 cursor-pointer select-none -mt-1'>{challengeDayData.challenge_date}</p>
                </div>
                <div className='flex border-gray-200 border bg-white rounded-full  flex-col justify-center py-1 w-full items-center '>
                  <h1 className='text-[15px] font-semibold text-gray-700 cursor-pointer select-none'>Challenge End</h1>
                  <p className='text-[15px] font-[500] text-gray-700 cursor-pointer select-none -mt-1'>{user.endChallenge}</p>
                </div>

              </div>

              <div className="flex justify-between  items-center w-full bg-white shadow border rounded-full px-6 py-3">

                <label className="relative flex items-center  rounded-full cursor-pointer" htmlFor="check">
                  <input type="checkbox" checked={challengeDayData.reading} onChange={(e) => handleChange(e)} name="reading"
                    className="before:content[''] peer relative h-6 w-6 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-orange-600 checked:bg-orange-500 checked:before:bg-orange-500 hover:before:opacity-10"
                    id="check" />
                  <span
                    className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                      stroke="currentColor" strokeWidth="1">
                      <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"></path>
                    </svg>
                  </span>
                </label>
                <label className="mt-px md:text-[18px] text-[16px] font-normal text-gray-700 cursor-pointer select-none" htmlFor="check">
                  القراءة - يوميا <span className='font-bold'>10</span>  صفحات ل <span className='font-bold'>20</span> صفحة
                </label>

                <div className=''>
                  <img src={reading} className='h9 w-9' alt='' />
                </div>
              </div>

              <div className="flex justify-between items-center w-full bg-white shadow border rounded-full px-6 py-3">

                <label className="relative flex items-center  rounded-full cursor-pointer" htmlFor="check1">
                  <input type="checkbox" checked={challengeDayData.writegoals} onChange={(e) => handleChange(e)} name="writegoals"
                    className="before:content[''] peer relative h-6 w-6 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-orange-600 checked:bg-orange-500 checked:before:bg-orange-500 hover:before:opacity-10"
                    id="check1" />
                  <span
                    className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                      stroke="currentColor" strokeWidth="1">
                      <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"></path>
                    </svg>
                  </span>
                </label>
                <label className="mt-px md:text-[18px] text-[16px] font-normal text-gray-700 cursor-pointer select-none" htmlFor="check1">
                  كتابة الأهداف - <span className='font-bold'>6</span> أهداف ( <span className='font-bold'>3</span> أشهر )
                </label>
                <div className=''>
                  <img src={writegoals} className='h9 w-9' alt='' />
                </div>
              </div>

              <div className="flex justify-between items-center w-full bg-white shadow border rounded-full px-6 py-3">

                <label className="relative flex items-center  rounded-full cursor-pointer" htmlFor="check2">
                  <input type="checkbox" checked={challengeDayData.walking} onChange={(e) => handleChange(e)} name="walking"
                    className="before:content[''] peer relative h-6 w-6 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-orange-600 checked:bg-orange-500 checked:before:bg-orange-500 hover:before:opacity-10"
                    id="check2" />
                  <span
                    className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                      stroke="currentColor" strokeWidth="1">
                      <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"></path>
                    </svg>
                  </span>
                </label>
                <label className="mt-px md:text-[18px] text-[16px] font-normal text-gray-700 cursor-pointer select-none" htmlFor="check2">
                  <span className='font-bold'>15</span> دقيقة مشي مع تخيل حدوث ما تريد
                </label>
                <div className=''>
                  <img src={walking} className='h9 w-9' alt='' />
                </div>
              </div>

              <div className="flex justify-between items-center w-full bg-white shadow border rounded-full px-6 py-3">

                <label className="relative flex items-center  rounded-full cursor-pointer" htmlFor="check3">
                  <input type="checkbox" checked={challengeDayData.deepwork} onChange={(e) => handleChange(e)} name="deepwork"
                    className="before:content[''] peer relative h-6 w-6 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-orange-600 checked:bg-orange-500 checked:before:bg-orange-500 hover:before:opacity-10"
                    id="check3" />
                  <span
                    className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                      stroke="currentColor" strokeWidth="1">
                      <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"></path>
                    </svg>
                  </span>
                </label>
                <label className="mt-px md:text-[18px] text-[16px] font-normal text-gray-700 cursor-pointer select-none" htmlFor="check3">
                  <span className='font-bold'>4</span> ساعات عمل مركز
                </label>
                <div className=''>
                  <img src={deepwork} className='h9 w-9' alt='' />
                </div>
              </div>

              <div className="flex justify-between items-center w-full bg-white shadow border rounded-full px-6 py-3">

                <label className="relative flex items-center  rounded-full cursor-pointer" htmlFor="check4">
                  <input type="checkbox" checked={challengeDayData.learning} onChange={(e) => handleChange(e)} name="learning"
                    className="before:content[''] peer relative h-6 w-6 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-orange-600 checked:bg-orange-500 checked:before:bg-orange-500 hover:before:opacity-10"
                    id="check4" />
                  <span
                    className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                      stroke="currentColor" strokeWidth="1">
                      <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"></path>
                    </svg>
                  </span>
                </label>
                <label className="mt-px md:text-[18px] text-[16px] font-normal text-gray-700 cursor-pointer select-none" htmlFor="check4">
                  <span className='font-bold'>30</span> دقيقة تعلم شيء جديد / مهارة جديدة
                </label>
                <div className=''>
                  <img src={learning} className='h9 w-9' alt='' />
                </div>
              </div>




              <div className="flex justify-between items-center w-full bg-white shadow border rounded-full px-6 py-3">

                <label className="relative flex items-center  rounded-full cursor-pointer" htmlFor="check5">
                  <input type="checkbox" checked={challengeDayData.diet} onChange={(e) => handleChange(e)} name="diet"
                    className="before:content[''] peer relative h-6 w-6 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-orange-600 checked:bg-orange-500 checked:before:bg-orange-500 hover:before:opacity-10"
                    id="check5" />
                  <span
                    className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                      stroke="currentColor" strokeWidth="1">
                      <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"></path>
                    </svg>
                  </span>
                </label>
                <label className="mt-px md:text-[18px] text-[16px] font-normal text-gray-700 cursor-pointer select-none" htmlFor="check5">
                  اتباع نظام غدائي و احترامه
                </label>
                <div className=''>
                  <img src={diet} className='h9 w-9' alt='' />
                </div>
              </div>

              <div className="flex justify-between items-center w-full bg-white shadow border rounded-full px-6 py-3">

                <label className="relative flex items-center  rounded-full cursor-pointer" htmlFor="check6">
                  <input type="checkbox" checked={challengeDayData.workout} onChange={(e) => handleChange(e)} name="workout"
                    className="before:content[''] peer relative h-6 w-6 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-orange-600 checked:bg-orange-500 checked:before:bg-orange-500 hover:before:opacity-10"
                    id="check6" />
                  <span
                    className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                      stroke="currentColor" strokeWidth="1">
                      <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"></path>
                    </svg>
                  </span>
                </label>
                <label className="mt-px md:text-[18px] text-[16px] font-normal text-gray-700 cursor-pointer select-none" htmlFor="check6">
                  التدريب يوميا دون الاستراحة ولا يوم
                </label>
                <div className=''>
                  <img src={workout} className='h9 w-9' alt='' />
                </div>
              </div>
              <div className='flex mt-4 items-center w-full justify-center ltr gap-2'>
                <button onClick={(e) => UpdateChallenge(e)} className="flex justify-center items-center gap-3 w-full select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-4 px-6 rounded-full bg-gray-900 text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 " type="button">
                  {loadingSave ?
                    <div class="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-white rounded-full " role="status" aria-label="loading">
                      <span class="sr-only">Loading...</span>
                    </div> :
                    <>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-save"><path d="M15.2 3a2 2 0 0 1 1.4.6l3.8 3.8a2 2 0 0 1 .6 1.4V19a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2z" /><path d="M17 21v-7a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v7" /><path d="M7 3v4a1 1 0 0 0 1 1h7" /></svg>                      Save
                    </>
                  }

                </button>
              </div>

              <div className='flex  items-center w-full justify-center ltr gap-2'>
                <button onClick={handleEmailClick} className="flex justify-center items-center gap-3 w-full select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-4 px-6 rounded-full bg-gray-900 text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none" type="button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide text-gray-100 lucide-mail-check"><path d="M22 13V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v12c0 1.1.9 2 2 2h8" /><path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" /><path d="m16 19 2 2 4-4" /></svg>
                  Contact Us
                </button>
                <button onClick={(e) => downloadImage()} className="flex justify-center items-center gap-3 w-full select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-4 px-6 rounded-full bg-gray-900 text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none" type="button">
                  {loading ?
                    <div class="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-white rounded-full " role="status" aria-label="loading">
                      <span class="sr-only">Loading...</span>
                    </div> :
                    <>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-image-down text-gray-100"><path d="M10.3 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v10l-3.1-3.1a2 2 0 0 0-2.814.014L6 21" /><path d="m14 19 3 3v-5.5" /><path d="m17 22 3-3" /><circle cx="9" cy="9" r="2" /></svg>
                      Download
                    </>
                  }

                </button>

              </div>
            </div>
            <ModalPrint formRef={formRef} challengeDayData={challengeDayData} user={user} />
          </form>

        </main>
      </div>


    </div>


  );
}

export default ChallengeForm;
