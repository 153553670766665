import './App.css';
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routers from './Routers/Routers';
import { BrowserRouter } from 'react-router-dom';
import axiosInstance from '../src/utils/axios/axiosConfig'
import { toast } from 'react-toastify'
import Cookies from 'js-cookie'

function App() {
  const [user, setUser] = useState(null); // Use null to represent the initial loading state
  const [loading, setLoading] = useState(true); // Loading state to manage initial fetch
  let token = Cookies.get('accessToken');

  useEffect(() => {
    if (token) {
      getUser();
    } else {
      setLoading(false); // If there's no token, stop loading
    }
  }, []);

  const getUser = async () => {
    try {
      const result = await axiosInstance.post('/users/getOne');
      if (result.data.success) {
        setUser(result.data.data);
        Cookies.set('role', result.data.data.role)

      } else {
        toast.error(result.data.message);
      }
    } catch (error) {
      toast.error('An error occurred while fetching user data');
    } finally {
      setLoading(false); // Stop loading after the fetch is complete
    }
  };

  if (loading) {
    return <div>Loading...</div>; // You can replace this with a proper loading indicator
  }

  return (
    <div className="md:px-12 px-4 w-full h-full bg-orange-50 flex items-center justify-center">
      <BrowserRouter>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Routers  />
      </BrowserRouter>
    </div>
  );
}

export default App;
