import React, { useState } from 'react'
import axiosInstance from '../../utils/axios/axiosConfig'
import Cookies from 'js-cookie'

function Dropdown({position}) {
    const [toggle, setToggle] = useState(false)
    const HandleLogOut = async () => {
        const result = await axiosInstance.post('/users/logout')
        if (result.data.success) {
            Cookies.remove('accessToken');
            window.location.reload()

        }

    }
    return (
        <div className={`absolute ${position ? position : 'right-0'} top-2  relative w-full `}>
            <button onClick={() => setToggle(!toggle)} className='w-8 h-8 flex justify-center items-center bg-white border border-gray-200 rounded-md'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-4">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
                </svg>


            </button>
            {toggle &&
                <div className={`absolute  ${position ? position : 'right-0'} z-10 top-10  flex justify-center flex-col items-center bg-white border border-gray-200 rounded-md px-3 py-2 gap-2`}>
                    <button onClick={() => HandleLogOut()} className='ltr gap-2 w-full py-1 px-4  flex justify-start items-center bg-gray-100 rounded-md'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15" />
                        </svg>

                        Log Out


                    </button>
                    <button onClick={() => setToggle(!toggle)} className='ltr gap-2  w-full py-1 px-4 flex justify-start items-center bg-gray-100   rounded-md'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="size-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25a3 3 0 0 1 3 3m3 0a6 6 0 0 1-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1 1 21.75 8.25Z" />
                        </svg>

                        Change Password

                    </button>
                </div>
            }
        </div>
    )
}

export default Dropdown