import { Routes, Route, Navigate } from 'react-router-dom';
import { Authentification, ChallengeForm, NotFoundPage, Users } from '../pages';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import React, { useState, useEffect } from 'react'


const Routers = () => {

  return (
    <div className='w-full h-full'>
      <Routes>

        <Route
          path="/challenge"
          element={
            <ProtectedRoute allowedRoles={['User']} >
              <ChallengeForm />
            </ProtectedRoute>
          }
        />

        <Route
          path="/Users"
          element={
            <ProtectedRoute  allowedRoles={['Admin']} >
              <Users />
            </ProtectedRoute>
          }
        />


        <Route path="/"
          element={
            <PublicRoute>
              <Authentification />
            </PublicRoute>
          } />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};

export default Routers;
