import React, { useState, useEffect } from 'react'
import axiosInstance from '../../utils/axios/axiosConfig'
import { toast } from 'react-toastify'
import ReactPaginate from 'react-paginate';
import { Dropdown } from '../../components';

function Users() {

  const [users, setUsers] = useState([])
  const [filterValue, setFilterValue] = useState(null)

  useEffect(() => {
    getUsers()
  }, [])

  const getUsers = async () => {
    const result = await axiosInstance.post('/users/get')
    if (result.data.success) {
      setUsers(result.data.data)
    } else {
      toast.error(result.data.message)
    }
  }

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10; // change this as needed
  const pageCount = Math.ceil(users.length / itemsPerPage);
  const displayedItems = users.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const filterUsers = async (e) => {
    e.preventDefault();
    const result = await axiosInstance.post('/users/filter', { value: filterValue })
    if (result.data.success) {
      setUsers(result.data.data)
    } else {
      toast.error(result.data.message)
    }
  }




  return (
    <div className='relative w-full  py-5  flex justify-start items-start flex-col h-full'>
      <Dropdown position='end' />
      <header className="ltr w-full flex md:flex-row flex-col gap-6 md:justify-between items-center ">
        <div className="w-full grid gap-4 text-start ">
          <h1 className='ltr text-[36px] font-[700]  leading-[45px]'>90 days <span className='uppercase text-orange-500'>#w3r</span> daily routine Users</h1>
          <p className='ltr text-start  text-[16px] font-[400] challenge-form'>خدم و تقاتل باش تكون 1%  من الناس و عفط ليهوم على الراس 💪📖🌟</p>
        </div>

        <div class="md:w-1/2 w-full mx-auto ltr ">
          <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only ">Search</label>
          <div class="relative">
            <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
              </svg>
            </div>
            <input onChange={(e) => { setFilterValue(e.target.value) }} type="search" id="default-search" class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 outline-none  " placeholder="Search Mockups, Logos..." required />
            <button type="submit" onClick={(e) => filterUsers(e)} disabled={!filterValue} class="text-white absolute end-[85px] bottom-2.5 bg-orange-500 hover:bg-orange-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2">Search</button>
            <button  onClick={(e) => getUsers(e)} class="text-white absolute end-2.5 bottom-2.5 bg-orange-500 hover:bg-orange-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2">Clear</button>

          </div>
        </div>

      </header>
      <section className="w-full ltr mx-auto">
        <div className="flex flex-col">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden  ">
                <table className="table-auto w-full shadow-md mt-5 rounded  border-separate border-spacing-y-3 ">
                  <thead className="bg-gray-50 text-left text-gray-500 tracking-wider h-[4rem] rounded-xl">
                    <tr>
                      <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                        FullName
                      </th>
                      <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                        Email
                      </th>
                      <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                        Phone
                      </th>
                      <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                        Start Challenge
                      </th>
                      <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                        End Challenge
                      </th>
                      <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                        Role
                      </th>
                      {/* <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                        Status
                      </th> */}
                      <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 ">
                        Actions
                      </th>

                    </tr>
                  </thead>
                  <tbody className="bg-white rounded-xl ">
                    {displayedItems.length > 0 ?
                      (
                        displayedItems.map(user => {
                          return (
                            <tr className='hover:scale-[1.02] transition-all duration-300  h-[4.5rem] '>
                              <td className="px-4 py-4 text-sm text-gray-500  whitespace-nowrap">{user.fullName}</td>
                              <td className="px-4 py-4 text-sm text-gray-500  whitespace-nowrap">{user.email}</td>

                              <td className="px-4 py-4 text-sm text-gray-500  whitespace-nowrap">{user.phone}</td>
                              <td className="px-4 py-4 text-sm text-gray-500  whitespace-nowrap">{user.startChallenge}</td>
                              <td className="px-4 py-4 text-sm text-gray-500  whitespace-nowrap">{user.endChallenge}</td>

                              <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                                <div className="inline-flex items-center px-3 py-1 rounded-full gap-x-2 text-orange-500 bg-orange-100/60 ">

                                  <h2 className="text-sm font-normal">{user.role}</h2>
                                </div>
                              </td>


                              <td className="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                                <a className="inline-flex cursor-pointer items-center px-3 py-1 rounded-full gap-x-2 text-emerald-500 bg-emerald-100/60 ">
                                  <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 3L4.5 8.5L2 6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>
                                  <h2 className="text-sm font-normal">Challenge details</h2>
                                </a>
                              </td>
                            </tr>
                          )
                        })
                      )
                      :
                      (
                        <div>

                        </div>
                      )

                    }


                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="relative mt-6">
          <ReactPaginate
            pageCount={pageCount}
            onPageChange={({ selected }) => setCurrentPage(selected)}
            containerClassName=" w-full flex justify-center  items-center "
            pageClassName="border  flex justify-center items-center ml-2 w-8  h-8 rounded-md"
            activeClassName="bg-orange-500 text-white"
            previousClassName="bg-white border flex justify-center items-center text-neutral-800 hover:text-orange-500 border-gray-200 absolute left-0 px-6 py-2 rounded-md"
            nextClassName="bg-white border flex justify-center items-center text-neutral-800 hover:text-orange-500 border-gray-200 absolute right-0   px-6 py-2 rounded-md"
            disabledClassName="opacity-50 cursor-not-allowed"
            breakClassName="mx-2"
            pageLinkClassName="hover:text-orange-500"
            previousLinkClassName="hover:text-orange-500"
            nextLinkClassName="hover:text-orange-500"
            breakLinkClassName="hover:text-orange-500"
          />
        </div>
      </section>


    </div>
  )
}

export default Users