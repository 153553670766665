import React from 'react'
const ModalPrint = ({ formRef, user, challengeDayData }) => {
    const now = new Date();
    const formattedDate = new Intl.DateTimeFormat('fr-FR', { day: 'numeric', month: 'long' }).format(now);

    return (
        <div className='modalPrint top-0 flex flex-col justify-start items-center  w-full gap-3  bg-white px-6 pt-6 pb-12' ref={formRef}>
            <header className="w-full grid text-center ">
                <h1 className='ltr text-[28px] font-[700] md:px-12 leading-[35px]'> <span className='uppercase text-orange-500'>#w3r</span> Challenge  Day {challengeDayData.day_number}</h1>
                <div className='flex justify-center flex-col items-center mb-2 ltr '>
                    <p className='ltr text-[20px] font-[500]  leading-[35px]'> {user.fullName} </p>
                    <p className='ltr text-[20px] font-[500]  leading-[35px]'> {challengeDayData.challenge_date}  </p>

                </div>

            </header>
            <div className='flex w-full  justify-start items-center gap-3 '>
                <div className='border-2 flex justify-center items-center border-green-500 rounded-full p-0.5   text-green-500'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" className="lucide  lucide-check"><path d="M20 6 9 17l-5-5" /></svg>
                </div>
                <p className='challenge-form text-[18px] -mt-5  font-normal text-gray-700 '>
                    القراءة - يوميا <span className='font-bold'>10</span>  صفحات ل <span className='font-bold'>20</span> صفحة
                </p>
            </div>

            <div className='flex w-full challenge-form justify-start items-center gap-3 '>
                <div className='border-2 flex justify-center items-center border-green-500 rounded-full p-0.5   text-green-500'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" className="lucide  lucide-check"><path d="M20 6 9 17l-5-5" /></svg>
                </div>
                <p className=' text-[18px] -mt-5  font-normal text-gray-700 cursor-pointer select-none'>
                    كتابة الأهداف - <span className='font-bold'>6</span> أهداف ( <span className='font-bold'>3</span> أشهر )
                </p>
            </div>
            <div className='flex w-full challenge-form justify-start items-center gap-3 '>
                <div className='border-2 flex justify-center items-center border-green-500 rounded-full p-0.5   text-green-500'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" className="lucide  lucide-check"><path d="M20 6 9 17l-5-5" /></svg>
                </div>
                <p className=' text-[18px] -mt-5  font-normal text-gray-700 cursor-pointer select-none'>
                    <span className='font-bold'>15</span> دقيقة مشي مع تخيل حدوث ما تريد
                </p>
            </div>
            <div className='flex w-full challenge-form justify-start items-center gap-3 '>
                <div className='border-2 flex justify-center items-center border-green-500 rounded-full p-0.5   text-green-500'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" className="lucide  lucide-check"><path d="M20 6 9 17l-5-5" /></svg>
                </div>
                <p className=' text-[18px] -mt-5  font-normal text-gray-700 cursor-pointer select-none'>
                    <span className='font-bold'>4</span> ساعات عمل مركز
                </p>
            </div>
            <div className='flex w-full challenge-form justify-start items-center gap-3 '>
                <div className='border-2 flex justify-center items-center border-green-500 rounded-full p-0.5   text-green-500'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" className="lucide  lucide-check"><path d="M20 6 9 17l-5-5" /></svg>
                </div>
                <p className=' text-[18px] -mt-5  font-normal text-gray-700 cursor-pointer select-none'>
                    <span className='font-bold'>30</span> دقيقة تعلم شيء جديد / مهارة جديدة
                </p>
            </div>
            <div className='flex w-full challenge-form justify-start items-center gap-3 '>
                <div className='border-2 flex justify-center items-center border-green-500 rounded-full p-0.5   text-green-500'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" className="lucide  lucide-check"><path d="M20 6 9 17l-5-5" /></svg>
                </div>
                <p className=' text-[18px] -mt-5  font-normal text-gray-700 cursor-pointer select-none'>
                    اتباع نظام غدائي و احترامه
                </p>
            </div>
            <div className='flex w-full challenge-form justify-start items-center gap-3 '>
                <div className='border-2 flex justify-center items-center border-green-500 rounded-full p-0.5   text-green-500'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" className="lucide  lucide-check"><path d="M20 6 9 17l-5-5" /></svg>
                </div>
                <p className=' text-[18px] -mt-5  font-normal text-gray-700 cursor-pointer select-none'>
                    التدريب يوميا دون الاستراحة ولا يوم
                </p>
            </div>

        </div>
    )
}

export default ModalPrint